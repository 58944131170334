const MIN_GRID = 3;
const MAX_PRICE = 100;
const MIN_PRICE = 10;
const MAX_SAVINGS = 45;
const MIN_SAVINGS = 2;

module.exports = {
    MIN_GRID,
    MAX_PRICE,
    MIN_PRICE,
    MIN_SAVINGS,
    MAX_SAVINGS
}